/* eslint-disable react/jsx-no-target-blank */
import map from 'lodash/map';
import { BUTTONS } from '../consts';
import parse from 'html-react-parser';
import SVG from 'react-inlinesvg';
import styles from './Links.module.scss';

export const Links = () => {
    return (
        <nav className={ styles.links }>
            <ul className={ styles.list }>
                { map(BUTTONS, (item, index) => (
                    <li key={ index }>
                        <a
                            href={ item.href }
                            target="_blank"
                            className={ styles.button }
                        >
                            <span className={ styles.text }>{ parse(item.title) }</span>
                            <span className={ styles.icon }>
                                <SVG src={ `/img/icons/icon-header-link-${item.icon}.svg` } />
                            </span>
                        </a>
                    </li>
                )) }
            </ul>
        </nav>
    )
}
