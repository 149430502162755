const appContextInitial = {
    modalIsOpen: false,
    isOpenedAuth: false,
    isOpenedRegistration: false,
    isOpenedRestoreEmail: false, // восстановление пароля - email
    isOpenedRestorePassword: false, // восстановление пароля - сброс пароля
    isOpenedTicketsPurchase: false, // основная покупка
    isOpenedTicketsSubPurchase: false, // доп покупка
    activeTicketID: 0,
    userData: null,
    orderId: null,
    ticketElementId: null,
    ticketType: null,
    ticketsData: null,
    manualModal: false,
    speakerModal: null,
    sliderModal: null,
};

const fetchDataInitial = {
    header: null, // Ссылки бургер меню
    tickets: null, // Билеты
}

export { appContextInitial, fetchDataInitial };
