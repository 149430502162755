import { useRef, useState, useEffect, useLayoutEffect } from 'react'
import cn from 'classnames'
import { useBreakpoint } from '../../hooks/useBreakpoint'
import { useGSAP } from '@gsap/react';
import gsapInstance from 'gsap';

import styles from './Header.module.scss'

import { Links } from './Links/Links'
import { BurgerSvgComponent } from "./BurgerSvgComponent";
import { Burger } from './Burger/Burger'
import { useLocation } from 'react-router-dom';
// import { BUTTON } from './consts';

export const Header = () => {
  const { pathname } = useLocation();
  const [isShowBurger, setShowBurger] = useState(false)
  const breakpoint = useBreakpoint()
  const [isScrolled, setIsScrolled] = useState(false);
  const burgerRef = useRef(null);
  const timelineRef = useRef(null);

  useGSAP(() => {
    if(burgerRef.current) {
      timelineRef.current = gsapInstance
        .timeline({
          paused: true,
          onReverseComplete: () => {
            setShowBurger(false)
          }
        })
        .from(burgerRef.current, { yPercent: -100 })
    }

    return () => {
      timelineRef.current?.kill();
    }
  }, [ burgerRef ])

  useLayoutEffect(() => {
    const handleScroll = () => {
      // Устанавливаем состояние в true, если страница проскроллена
      const position = window.pageYOffset;
      setIsScrolled(position > 0);
    };

    // Добавляем обработчик события скролла
    window.addEventListener('scroll', handleScroll);

    // Удаляем обработчик события при размонтировании компонента
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if(isShowBurger)
      document.documentElement.classList.add('no-scroll')

    return () => {
      if(isShowBurger && document.documentElement.classList.contains('no-scroll'))
        document.documentElement.classList.remove('no-scroll')
    }
  }, [isShowBurger])

  const handlerToggleBurger = () => {
    if(!isShowBurger) {
      setShowBurger(true);
      timelineRef.current?.play();
    } else {
      handlerCloseBurger();
    }
  }

  const handlerCloseBurger = () => {
    timelineRef.current?.timeScale(1.5).reverse();
  }

  return (
    <>
      <header
        className={cn(
          styles['header'],
          {
            [styles['header--show-burger']]: isShowBurger,
            [styles['header--scrolled']]: isScrolled,
          }
        )}
      >
        <div id="header" className={ styles['header__inner'] }>
          { breakpoint === 'desktop' ? (
            <>
              <div className={ styles['header__side-left'] }>
                <a href="/" className={ styles['header__logo'] }>
                  <img src='/img/logos/southhub.svg' alt="SouthHub" />
                </a>
              </div>
              <div className={ styles['header__side-middle'] }>
                { pathname !== '/' && (
                  <button
                    type="button"
                    className={ cn(
                      styles['burger-button'],
                      styles[`burger-button--${isShowBurger ? 'close' : 'open'}`]
                    ) }
                    onClick={ handlerToggleBurger }
                  >
                    <BurgerSvgComponent isClose={ isShowBurger } />
                  </button>
                ) }
              </div>
              <div className={ styles['header__side-right'] }>
                { pathname === '/' && (
                  <Links />
                ) }
              </div>
            </>
          ) : (
            <>
              <a href="/" className={ styles['header__logo'] }>
                <img src='/img/logos/southhub.svg' alt="SouthHub" />
              </a>
              { pathname === '/' ? (
                <Links />
              ) : (
                <button
                  type="button"
                  className={ cn(
                    styles['burger-button'],
                    styles[`burger-button--${isShowBurger ? 'close' : 'open'}`]
                  ) }
                  onClick={ handlerToggleBurger }
                >
                  <BurgerSvgComponent isClose={ isShowBurger } />
                </button>
              ) }
            </>
          ) }

        </div>

        <Burger ref={ burgerRef } handlerCloseBurger={ handlerCloseBurger } />
      </header>
    </>
  )
}
