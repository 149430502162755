import { defer } from 'react-router-dom';

async function getSpeakers() {
}

export default async function speakersLoader() {
    return defer({
        speakers: getSpeakers()
    })
}
