import { TRANSLATION } from './i18'
import classNames from 'classnames'
import PoweredBy from '../PoweredBy';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import map from 'lodash/map';
import flatten from 'lodash/flatten';
import { LINKS, CONTACTS } from './consts';
import styles from './FooterItems.module.scss'

export const FooterItems = (props) => {  
  const breakpoint = useBreakpoint();

  return (
    <div className={classNames(styles['footer-items'])}>
      <div className={classNames(styles['footer-items__item'])}>
        <h2>{TRANSLATION.ru.info.title}</h2>
        <div>{TRANSLATION.ru.info.organization}</div>
        <div>{TRANSLATION.ru.info.inn}</div>
        <a href="/files/privacy_policy.pdf" download>{TRANSLATION.ru.info.privacy_policy}</a>
        <a href="/files/cookie_terms_use.pdf" download>{TRANSLATION.ru.info.cookie_terms_use}</a>
      </div>
      { breakpoint === 'desktop' ? (
        map(LINKS, (sublinks, sublinksIndex) => (
          <div className={classNames(styles['footer-items__item'])}>
            <ul key={ sublinksIndex } className={classNames(styles['footer-items__links'])}>
              { map(sublinks, (item, index) => (
                <li key={sublinksIndex+'-'+index} className={classNames(styles['footer-items__links-item'])}>
                  <h2>{ item.title }</h2>
                  <ul className={classNames(styles['footer-items__links-list'])}>
                    { map(item.links, (link, j) => (
                      <li key={ sublinksIndex+'-'+index+'-'+j }>
                        <a
                          className={classNames(styles['footer-items__links-name'])}
                          href={ link.href }
                          target="_blank"
                          rel="noreferrer"
                        >{link.text}</a>
                      </li>
                    )) }
                  </ul>
                </li>
              )) }
            </ul>
          </div>
        ))
      ) : (
        <div className={classNames(styles['footer-items__item'])}>
          <ul className={classNames(styles['footer-items__links'])}>
            { map(flatten(LINKS), (item, index) => (
              <li key={index} className={classNames(styles['footer-items__links-item'])}>
                <h2>{ item.title }</h2>
                <ul className={classNames(styles['footer-items__links-list'])}>
                  { map(item.links, (link, j) => (
                    <li key={ index+'-'+j }>
                      <a
                        className={classNames(styles['footer-items__links-name'])}
                        href={ link.href }
                        target="_blank"
                        rel="noreferrer"
                      >{link.text}</a>
                    </li>
                  )) }
                </ul>
              </li>
            )) }
          </ul>
        </div>
      ) }
      <div className={classNames(styles['footer-items__item'])}>
        <h2>{TRANSLATION.ru.contacts.title}</h2>
        <a href={CONTACTS.tg} target="_blank" rel="noreferrer">Telegram</a>
        <a href={'mailto:'+CONTACTS.email}>{CONTACTS.email}</a>
        <a href={'tel:'+CONTACTS.number.replace(/\s/g, '')}>{CONTACTS.number}</a>
        <PoweredBy className={ styles.poweredBy } />
      </div>
    </div>
  )
}