import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {AppContextProvider} from "./context/appContext";
import {AppLayout} from "./layouts/App/AppLayout";
import ScrollTrigger from 'gsap/ScrollTrigger';
import gsap from 'gsap';

gsap.registerPlugin(ScrollTrigger);

ScrollTrigger.config({
    autoRefreshEvents: 'load,resize'
});

const queryClient = new QueryClient()

export function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <AppContextProvider>
                <AppLayout/>
            </AppContextProvider>
        </QueryClientProvider>
    )
}
