import { useLayoutEffect, useState, useRef } from 'react'
import classNames from 'classnames'
import gsap from 'gsap'
import styles from './Preloader.module.scss'
import {Loader} from "./Loader";

export const Preloader = () => {
  const preloaderPef = useRef()
  const [showPreloader, setShowPreloader] = useState(document.readyState === 'complete' ? false : true)
  const scrollingPrevent = e => e.preventDefault()


  useLayoutEffect(() => {
    const scrollingDisabled = (boolean) => {
      ['scroll', 'wheel', 'touchmove', 'pointermove'].forEach((e, i) => {
        if (boolean === true) {
          (i ? document : window).addEventListener(e, scrollingPrevent, {passive: false})
        } else {
          (i ? document : window).removeEventListener(e, scrollingPrevent)
        }
      })
    }
    scrollingDisabled(showPreloader)

    const preloaderHandler = () => {
      gsap.to(preloaderPef.current, {
        opacity: 0,
        onComplete() {
          scrollingDisabled(false)
          setShowPreloader(false)
        }
      })
    }
    window.addEventListener('load', preloaderHandler)

    return () => {
      window.removeEventListener('load', preloaderHandler)
      scrollingDisabled(false)
      setShowPreloader(false)
    }
  }, [showPreloader])

  return showPreloader ? (
    <div className={classNames(styles['preloader'])} ref={preloaderPef}>
      <div className={classNames(styles['preloader__logo'])} />
      <Loader />
    </div>
  ) : null
}
