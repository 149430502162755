const TRANSLATION = {
    ru: {
        location: {
            text: 'Сочи, Гранд Отель Поляна',
            href: 'location'
        },
        date: {
            text: '8-12 июня 2024',
            href: 'https://calendar.google.com/calendar/event?action=TEMPLATE&tmeid=MXR0NnM3aTE4MnM3c250Mm52dTlqaWlhMnAgY184OWZjMjBhMmVkZTVhNDgyYTM1Y2YwYzRhMmU1OTI5NGQ5YzliZjZmZTJjZjU1MTk3YTBhZTQzYjliODFlYzA3QGc&tmsrc=c_89fc20a2ede5a482a35cf0c4a2e59294d9c9bf6fe2cf55197a0ae43b9b81ec07%40group.calendar.google.com'
        },
    }
}
  
export default TRANSLATION