export const LINKS = [
    [
        {
            title: 'Участие',
            links: [
                { text: 'Мария Шишкина', href: 'https://t.me/southhub_com' }
            ]
        },
        {
            title: 'Партнёры',
            links: [
                { text: 'Артём Степченко', href: 'https://t.me/Artem_Stepchenko' }
            ]
        },
        {
            title: 'Программа',
            links: [
                { text: 'Алина Саяпова', href: 'https://t.me/asayapova' }
            ]
        },
    ],
    [
        {
            title: 'История кэмпа',
            links: [
                { text: 'South HUB’23', href: 'https://club.southhub.ru/postrelease23' },
                { text: 'South HUB’22', href: 'https://club.southhub.ru/postrelease' }
            ]
        }
    ]
];

export const CONTACTS = {
    tg: 'https://t.me/+vCvlQD8d8YwyZjhi',
    email: 'mail@southhub.ru',
    number: '+7 862 29 59 977',
}