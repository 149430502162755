import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import { lazy, useEffect } from "react";
import { useHeaderHeight } from "../../hooks/useHeaderHeight";
import MainLayout from '../../layouts/MainLayout';
import SpeakersPageLoader from '../../pages/Speakers/loader';

const PostRealize = lazy(() => import('../../pages/PostRealize'));
const MainPage = lazy(() => import('../../pages/MainPage'));

const SpeakersPage = lazy(() => import('../../pages/Speakers'));
// const SpeakersPageLoader = lazy(() => import('../../pages/Speakers/loader'));

export const AppRoutes = () => {
    const headerHeight = useHeaderHeight();

    useEffect(() => {
        document.body.style.setProperty('--header-offset', headerHeight+'px');

        return () => {
            document.body.style.setProperty('--header-offset', '');
        }
    }, [ headerHeight ])

    const router = createBrowserRouter(createRoutesFromElements(
        <Route path="/" element={ <MainLayout /> }>
            <Route index element={ <PostRealize /> } />
            <Route path="speakers" element={ <SpeakersPage /> } loader={ SpeakersPageLoader } />
            <Route path="/2024" element={ <MainPage /> } />
        </Route>
    ));

    return (
        <RouterProvider router={ router } />
    );
};
