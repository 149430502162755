import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { useGSAP } from '@gsap/react';
import gsapInstance from 'gsap';

const PATH_OPEN_DESKTOP = 'M64 28 49 28C39 28 25 28 15 28L0 28M0 20 15 20C25 20 39 20 49 20L64 20';
const PATH_OPEN_MOBILE = 'M24 15 18 15C14 15 10 15 6 15L0 15M0 9 6 9C10 9 14 9 18 9L24 9';
const PATH_CLOSE_DESKTOP = 'M24 16 28.5 20.5C30.5 22.5 30.5 25.5 28.5 27.5L24 32M40 32 35.5 27.5C33.5 25.5 33.5 22.5 35.5 20.5L40 16';
const PATH_CLOSE_MOBILE = 'M4 4 8.5 8.5C10.5 10.5 10.5 13.5 8.5 15.5L4 20M20 20 15.5 15.5C13.5 13.5 13.5 10.5 15.5 8.5L20 4';

const BurgerSvgComponent = (props) => {
    const { isClose } = props;

    const breakpoint = useBreakpoint();
    const pathRef = useRef(null);
    const timelineRef = useRef(null);

    const [ dataSVG, setDataSVG ] = useState({
        width: breakpoint === 'desktop' ? 64 : 24,
        height: breakpoint === 'desktop' ? 48 : 24,
        viewbox: breakpoint === 'desktop' ? '0 0 64 48' : '0 0 24 24',
    })

    useLayoutEffect(() => {
        const newState = {
            width: breakpoint === 'desktop' ? 64 : 24,
            height: breakpoint === 'desktop' ? 48 : 24,
            viewbox: breakpoint === 'desktop' ? '0 0 64 48' : '0 0 24 24',
        }
        setDataSVG(newState)
    }, [ breakpoint ])

    useGSAP(() => {
        console.info('breakpoint', breakpoint);

        timelineRef.current = gsapInstance
            .timeline({
                paused: true
            })
            .fromTo(pathRef.current, 
                {
                    attr: {
                        d: breakpoint === 'desktop' ? PATH_OPEN_DESKTOP : PATH_OPEN_MOBILE
                    }
                }, {
                    attr: {
                        d: breakpoint === 'desktop' ? PATH_CLOSE_DESKTOP : PATH_CLOSE_MOBILE
                    }
                }
            )
            .progress(isClose ? 1 : 0)

        return () => {
            timelineRef.current?.kill();
        }
    }, [ breakpoint ])

    useEffect(() => {
        isClose ? timelineRef.current?.play() : timelineRef.current?.reverse()
    }, [ isClose ])

    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={ dataSVG.width }
            height={ dataSVG.height }
            viewBox={ dataSVG.viewbox }
            fill="none"
        >
            <path 
                stroke="#000"
                strokeWidth="1.5"
                // d={ dataSVG.path }
                ref={ pathRef }
            />
        </svg>
    )
}
export {BurgerSvgComponent}
