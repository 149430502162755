const imgReg = /\.(jpg|jpeg|png)$/;

const ImageComponent = ({ src, alt, ...props }) => {
  const image = () => {
    if(imgReg.test(src)) {
      const webp = src.replace(imgReg, '.webp');

      return (
        <picture>
          <source srcSet={ webp } type="image/webp" />
          <img src={ src } alt={ alt } { ...props } />
        </picture>
      )
    } else {
      return <img src={ src } alt={ alt } { ...props } />
    }
  }

  return image()
}

export default ImageComponent
