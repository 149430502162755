import cn from 'classnames';
import { Link } from 'react-scroll';

import TRANSLATION from '../../i18';
import { useHeaderHeight } from '../../../../hooks/useHeaderHeight';

import styles from './BannerLinks.module.scss';

export const BannerLinks = (props) => {
    const headerHeight = useHeaderHeight();

    return (
        <div className={cn(styles['banner__info'])}>
            <Link
                to={TRANSLATION.ru.location.href}
                spy={true}
                smooth={true}
                offset={ headerHeight }
                delay={0}
                isDynamic={true}
                duration={500}
                onClick={ props?.handlerCloseBurger || null }
            >
                <button
                    type="button"
                    className={cn(styles['banner__info-item'])}
                >
                    <span className={cn(styles['banner__info-item-text'])}>{TRANSLATION.ru.location.text}</span>
                    <span
                        className={cn(
                            styles['banner__info-item-icon'],
                            styles['banner__info-item-icon--geo']
                        )}
                    />
                </button>
            </Link>
            <a
                className={cn(styles['banner__info-item'])}
                href={TRANSLATION.ru.date.href}
                target="_blank"
                rel="noreferrer"
            >
                <span className={cn(styles['banner__info-item-text'])}>{TRANSLATION.ru.date.text}</span>
                <span
                    className={cn(
                        styles['banner__info-item-icon'],
                        styles['banner__info-item-icon--calendar']
                    )}
                />
            </a>
        </div>
    )
}