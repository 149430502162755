import { useLayoutEffect, useState } from 'react'

export const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState('')

  useLayoutEffect(() => {
    const mql = window.matchMedia('screen and (max-width: 768px) and (orientation: portrait)')

    const handleResize = (event) => {
      setBreakpoint(event.matches ? 'mobile' : 'desktop')
    }

    mql.addEventListener('change', handleResize)
    handleResize(mql)

    return () => mql.removeEventListener('change', handleResize)
  }, [])

  return breakpoint
}