import { useEffect, useRef } from 'react'

import classNames from 'classnames'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import { useBreakpoint } from '../../hooks/useBreakpoint'
import SplitType from 'split-type'
import map from 'lodash/map'
import Image from '../Image/Image'

import styles from './Title.module.scss'

gsap.registerPlugin(ScrollTrigger)

export const Title = (props) => {
  const titleParentRef = useRef(null)
  const titleRef = useRef([])
  const titleImageRef = useRef([])
  const splitTypeRef = useRef([])
  const breakpoint = useBreakpoint()
  const counterRef = useRef(null)
  const timelineRef = useRef(null)

  useEffect(() => {
    splitTypeRef.current = [];
    titleRef.current.forEach((el, index) => {
      if(el !== null) {
        splitTypeRef.current[index] = SplitType.create(el, {types: 'chars', tagName: 'span'}).chars
      }
    })

    timelineRef.current = gsap.timeline()

    if(splitTypeRef.current.length > 0) {
      const filteredsplitTypeRef = splitTypeRef.current.filter(el => el !== null);

      timelineRef.current.from(filteredsplitTypeRef, {
        stagger: .05,
        opacity: 0,
        yPercent: 100,
        clearProps: 'transform,opacity'
      }, '')

      if(counterRef.current) {
        timelineRef.current.from(counterRef.current, {
          opacity: 0,
          yPercent: 100,
          clearProps: 'transform,opacity'
        }, '>-=25%')
      }
    }

    if(titleImageRef.current.length > 0) {
      const filteredTitleImageRef = titleImageRef.current.filter(el => el !== null);
      timelineRef.current.from(filteredTitleImageRef, {
        stagger: .2,
        width: (_, target) => target.clientHeight,
        opacity: 0,
        clearProps: 'width,opacity'
      }, '-=1')
    }

    ScrollTrigger.create({
      trigger: titleParentRef.current,
      start: 'top bottom',
      // markers: true,
      animation: timelineRef.current,
      // once: true,
      fastScrollEnd: 3000,
    })

    return () => {
      // eslint-disable-next-line
      titleRef.current.forEach((el) => SplitType.revert(el))
      timelineRef.current?.kill()
    }
  }, [breakpoint])

  return breakpoint !== null ? (
    <h1
      className={classNames(
        styles['title'],
        {
          [styles['title--big']]: props.isBig,
          [styles['title--margin-bottom']]: props.isMarginBottom
        },
        props.className
      )}
      ref={titleParentRef}
    >
      { map(props.texts, (value, index) => (
        <span
          key={`${breakpoint}_${index}`}
          className={classNames(
            styles['title-line'],
            [styles['title-line--align-'+value.align]],
            {
              [styles['title-line--mobile-small']]: value.isMobileSmall
            }
          )}
        >
          { value.insertPad && (
            <span dangerouslySetInnerHTML={{ __html: value.insertPad}} style={{order: '-1'}} />
          ) }
          <span
            className={classNames(styles['title-line-text'])}
            ref={el => (titleRef.current[index] = el)}
            dangerouslySetInnerHTML={{ __html: value.text}}
          />
          {(index === props.texts.length - 1 && props.counter) && (
            <sup className={ styles['title-counter'] } ref={ counterRef } >{ props.counter }</sup>
          )}
          {value.insert &&
            <span
              className={classNames(
                styles['title-line-insert'],
                [styles['title-line-insert--'+(value.insertFloat ? value.insertFloat : value.align === 'right' ? 'left' : 'right' )]],
              )}
            >
              <span ref={el => (titleImageRef.current[index] = el)}>
                <Image src={value.insert} width="130" height="88" alt="" />
              </span>
            </span>
          }
        </span>
      )) }
    </h1>
  ) : null
}
