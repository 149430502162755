const currentLink = new URL(window.location.href);
const createLink = new URL('https://ticketscloud.com/v1/widgets/common?token=eyJhbGciOiJIUzI1NiIsImlzcyI6InRpY2tldHNjbG91ZC5ydSIsInR5cCI6IkpXVCJ9.eyJwIjoiNjU4ZDllNGMzMjRiZDExNjBjNDQ4ZmIwIn0.XIxuMdvIqbaSDfIWptnljw5lfnzIvkRM8dUzh4y0Gnw&lang=ru&event=658fdfe281aca55e24457047&s=1');

if(currentLink.searchParams) {
    for(const [key, value] of currentLink.searchParams.entries()) {
        createLink.searchParams.set(key, value)
    }
}

const BACKEND_URL = 'https://southhub.ru/api';

const FAMILY_URL = 'https://club.southhub.ru/family';
const TICKET_MAIN_URL = createLink.toString();

export { BACKEND_URL, TICKET_MAIN_URL, FAMILY_URL }
