export const TRANSLATION = {
  ru: {
    info: {
      title: 'Правовая информация',
      organization: 'ООО "Высокие люди”',
      inn: 'ИНН 2320134913',
      privacy_policy: 'Политика конфиденциальности',
      cookie_terms_use: 'Условия использования cookie',
    },
    contacts: {
      title: 'Контакты',
    }
  }
}