import map from 'lodash/map';
import { useBreakpoint } from '../../../../hooks/useBreakpoint';
import styles from './BurgerLinks.module.scss';
import { useAppContext } from '../../../../context/appContext';
import isNil from "lodash/isNil";
import startsWith from 'lodash/startsWith';
import trim from "lodash/trim";
import { TICKET_MAIN_URL } from '../../../../consts/consts';

export const BurgerLinks = () => {
    const breakpoint = useBreakpoint();
    const { fetchData } = useAppContext();

    const handlerLinkClick = ({ ymName = null, gtmName = null }) => {
        // eslint-disable-next-line no-undef
        if(ym && !isNil(ymName) && trim(ymName)!=='') {
            // eslint-disable-next-line no-undef
            ym?.(89187152, 'reachGoal', ymName)
        }
        // eslint-disable-next-line no-undef
        if(gtag && !isNil(gtmName) && trim(gtmName)!=='') {
            // eslint-disable-next-line no-undef
            gtag?.('event', gtmName)
        }
    }

    return (
        <nav className={ styles.container }>
            <ul className={ styles.list }>
                { map(fetchData.header, (item, index) => {
                    let link = item.href_mobile ? (breakpoint === 'desktop' ? item.href : item.href_mobile) : item.href;

                    if(startsWith(link, 'https://ticketscloud.com/v1/widgets/common?token=')) {
                        link = TICKET_MAIN_URL;
                    }

                    return (
                        <li key={ index }>
                            <a
                                className={ styles.link }
                                href={ link }
                                target="_blank"
                                rel="noreferrer"
                                onClick={ () => handlerLinkClick({ ymName: item?.ym_event, gtmName: item?.gtag_event }) }
                            >
                                { item.name }
                            </a>
                        </li>
                    )
                }) }
            </ul>
        </nav>
    )
}
