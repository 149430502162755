import { TRANSLATION } from './i18'

import classNames from 'classnames'

import { Title } from '../Title/Title'
import { FooterItems } from '../FooterItems/FooterItems'

import { useBreakpoint } from '../../hooks/useBreakpoint'

import styles from './Footer.module.scss'

export const Footer = (props) => {
  const breakpoint = useBreakpoint()

  return (
    <footer className={classNames(styles['footer'])}>
      <div className={classNames(styles['footer__inner'])}>
        <Title
          texts={breakpoint === 'desktop' ? [
            {text: TRANSLATION.ru.title.desktop[0], align: 'left'},
            {text: TRANSLATION.ru.title.desktop[1], align: 'left'},
          ] : [
            {text: TRANSLATION.ru.title.mobile[0], align: 'left'},
            {text: TRANSLATION.ru.title.mobile[1], align: 'left'},
            {text: TRANSLATION.ru.title.mobile[2], align: 'left'},
          ]}
        />
        <FooterItems />
      </div>
    </footer>
  )
}