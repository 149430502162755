import { BurgerLinks } from './BurgerLinks/BurgerLinks';
import { BannerLinks } from '../../Banner/components/BannerLinks';
import styles from './Burger.module.scss';
import { forwardRef } from 'react';

export const Burger = forwardRef((props, ref) => {
    return (
        <div className={ styles.overflow } ref={ ref }>
            <div className={ styles.content }>
                <BurgerLinks />
                <BannerLinks handlerCloseBurger={ props.handlerCloseBurger } />
            </div>
        </div>
    )
})
