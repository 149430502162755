import cn from 'classnames';
import styles from './PoweredBy.module.scss';

const PoweredBy = ({ className }) => {
    return (
        <a className={ cn(className, styles.poweredBy) } href="https://marus.team/">
            Сделано в
            <svg xmlns="http://www.w3.org/2000/svg" width="89" height="14" viewBox="0 0 89 14" fill="none">
                <path fill="#fff" d="M63.102 8.538a2.839 2.839 0 0 1-1.717 2.713c-.348.15-1.009.226-1.387.226-.378 0-1.038-.077-1.387-.226a2.826 2.826 0 0 1-1.532-1.596 2.82 2.82 0 0 1-.185-1.117V.5H54.78v8.038c0 .651.128 1.297.376 1.9a4.945 4.945 0 0 0 2.67 2.685c.599.249 1.525.377 2.172.377 1.308 0 2.846-.524 3.77-1.454a4.977 4.977 0 0 0 1.444-3.508V.5H63.1v8.038h.001ZM42.468.5h-6.64l1.005 2.279h5.676c1.006 0 1.834.815 1.85 1.821a1.785 1.785 0 0 1-1.789 1.821h-4.117l1.016 2.285 2.027 4.558h2.277l-2.028-4.558h.86a4.022 4.022 0 0 0 4.034-4.103A4.186 4.186 0 0 0 42.467.5ZM25.712.5h-.842l-5.776 12.762h2.348l3.85-8.476 3.737 8.476h2.35L25.711.5ZM73.21 2.556V.5h-2.116v3.372h1.52l.595-1.316ZM11.442.5 6.086 8.976.842.5H0v12.764h2.115v-6.68l3.551 5.684h.843l3.659-5.747v6.743h2.116V.5h-.842Zm76.636 9.465v-.373c0-1.478-1-2.764-2.431-3.128h-.005l-4.884-1.608a1.09 1.09 0 0 1 .273-2.142h5.175L87.17.578 81.03.574a3.233 3.233 0 0 0-3.23 3.23c0 1.478 1 2.764 2.433 3.128l4.89 1.608c.055.014.117.035.173.06a1.261 1.261 0 0 1-.402 2.454h-6.277l-.969 2.14h7.202a3.232 3.232 0 0 0 3.228-3.228v-.001Z" />
            </svg>
        </a>
    )
}

export default PoweredBy