import { TRANSLATION } from './i18'

import React, { useState, useRef, useEffect } from 'react'
import gsap from 'gsap'
import classNames from 'classnames'
import useCookie from '../../hooks/useCookie'

import styles from './CookiesNotify.module.scss'

export const CookiesNotify = () => {
  const [value, update] = useCookie('cookiesAccepted', 'false')
  const [showNotify, setShowNotify] = useState(value === 'false' ? true : false)
  const notifyRef = useRef(null)

  const handleAcceptCookies = () => {
    if (notifyRef.current) {
      gsap.to(notifyRef.current, {
        opacity: 0,
        yPercent: 100,
        onComplete() {
          update('true')
          setShowNotify(false)
        }
      })
    }
  }

  useEffect(() => {
    if (notifyRef.current) {
      gsap.from(notifyRef.current, {
        opacity: 0,
        yPercent: 100,
        delay: 1
      })
    }
  }, [])

  return showNotify ? (
    <div className={classNames(styles['cookies-notify'])} ref={notifyRef}>
      <div className={classNames(styles['cookies-notify__text'])} dangerouslySetInnerHTML={{__html: TRANSLATION.ru.text}} />
      <button className={classNames(styles['cookies-notify__btn'])} onClick={handleAcceptCookies}><span>OK</span></button>
    </div>
  ) : null
}