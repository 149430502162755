import { useEffect, useState } from 'react';

export const useHeaderHeight = () => {
  const [headerHeight, setHeaderHeight] = useState(0);
  const header = document.getElementById('header');

  useEffect(() => {
    const getHeaderHeight = () => {
      setHeaderHeight(header?.clientHeight || 0);
    }

    if(header) {
      window.addEventListener('DOMContentLoaded', getHeaderHeight)
      window.addEventListener('resize', getHeaderHeight)
      getHeaderHeight();
    }

    return () => {
      if(header) {
        window.removeEventListener('DOMContentLoaded', getHeaderHeight)
        window.removeEventListener('resize', getHeaderHeight)
        setHeaderHeight(0)
      }
    }
  }, [header])

  return headerHeight
}