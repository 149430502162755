import { AppRoutes } from "./routes";
import { CookiesNotify } from "../../components/CookiesNotify/CookiesNotify";

export const AppLayout = () => {
    return (
        <>
            <AppRoutes/>
            <CookiesNotify/>
        </>
    )
};