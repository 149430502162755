import { Outlet, useLocation } from 'react-router-dom';
import { Suspense, useEffect } from 'react';
import { Header } from '../../components/Header/Header';
import { Preloader } from '../../components/Preloader/Preloader';
import { Footer } from '../../components/Footer/Footer';
import styles from './MainLayout.module.scss';
import { useAppContext } from '../../context/appContext';

const PAGE_URL = 'https://new.southhub.ru/wp/wp-json/wp/v2/pages/30?acf_format=standard'

export default function MainLayout() {
    const pathname = useLocation();
    const { updateFetchData } = useAppContext();

    useEffect(() => {
        window.scrollTo(0, 0);

        const getData = async () => {
            const res = await fetch(PAGE_URL);
            const data = await res.json();

            updateFetchData({
                header: data.acf.menu,
                tickets: data.acf.tickets
            })
        }

        getData();
    }, [ pathname ]);

    return (
        <>
            <Header />
            <main>
                <Suspense fallback={
                    <>
                        <div className={ styles.preloader } />
                        <Preloader />
                    </>
                }>
                    <Outlet />
                </Suspense>
                <Footer />
            </main>
        </>
    )
}
