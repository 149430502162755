export const TRANSLATION = {
  ru: {
    title: {
      desktop: [
        'Для связи',
        'mail@southhub.ru',
      ],
      mobile: [
        'Для связи',
        'mail',
        '@southhub.ru'
      ]
    }
  }
}