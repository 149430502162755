import { createContext, useContext, useMemo, useState } from "react";
import { appContextInitial, fetchDataInitial } from "./consts";

const AppContext = createContext(undefined);

const AppContextProvider = ({children}) => {
    const [ appData, setAppData ] = useState(appContextInitial);

    // Данные компонентов полученные с сервера
    const [ fetchData, setFetchData ] = useState(fetchDataInitial);

    const updateAppData = (newData) => {
        setAppData({ ...appData, ...newData })
    }

    // Обновление данных полученных с сервера
    const updateFetchData = (newData) => {
        setFetchData({ ...fetchData, ...newData })
    }

    const memoizedValue = useMemo(() => ({
        appData, updateAppData,
        fetchData, updateFetchData
    }), [appData, fetchData]);

    return (
        <AppContext.Provider value={ memoizedValue }>
            { children }
        </AppContext.Provider>
    )
}

const useAppContext = () => {
    const context = useContext(AppContext);

    if (context === undefined) {
        throw new Error('useAppContext должен быть использован в AppContextProvider');
    }

    return context;
}

export { useAppContext, AppContextProvider }